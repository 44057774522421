import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useMatch } from '@reach/router'
import { navigate, Link } from 'gatsby'

import { Tag } from '@zendeskgarden/react-tags'

import {
  dashcase,
  stringEmpty,
  stringNotEmpty,
  ensureLeadingSlash,
  ensureArray,
  ensureObject,
} from '@agnostack/lib-core'

import { getTitle } from '../gatsby'
import {
  SEO,
  Callout,
  ScheduleAnchor,
  ListingFooter,
  Heading,
} from '../components/atoms'
import TemplatedPage from '../components/molecules/TemplatedPage'
import PageFragment from '../components/molecules/PageFragment'

const LinkGroup = styled.div`
  margin-bottom: 2em;
`

const TemplateTag = styled(Tag).attrs(({ template }) => ({
  isPill: true,
  size: 'large',
  ...stringNotEmpty(template) && {
    onClick: () => navigate(`/${dashcase(template)}`),
  },
}))`
  text-transform: capitalize;
  ${({ template }) => stringNotEmpty(template) && `
    &:hover {
      cursor: pointer;
    }
  `}
`

const GroupItem = styled.li`
  margin: .5em;
`

const StyledAnchor = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledCallout = styled(Callout).attrs(() => ({
  isNarrow: true,
  format: 'accent1',
}))`
  margin-bottom: 5em;
`

const ContentBody = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const LinkGroups = styled.div`
  min-width: 20rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: auto;
    max-width: 25vw;
  }
`

const FragmentBody = styled.div`
  flex-grow: 1;
`

const TAGLINE = 'resources'

export default withTheme(({
  theme: {
    palette,
  } = {},
  pageContext,
  pageContext: {
    tag: activeTag,
    tagData,
    tagGroups,
    fragments,
  } = {},
}) => {
  const {
    tagline: _tagline,
    modifier,
    modifier: {
      link: modifierLink,
      text: modifierText = '',
    } = {},
    prepend: {
      link: prependLink,
      text: prependText,
    } = {},
  } = ensureObject(tagData)

  const hues = Object.keys(ensureObject(palette)).filter((hue) => !['extended', 'black', 'white'].includes(hue))

  const { fragment, defaultFragment } = ensureArray(fragments).reduce((_fragments, _fragment) => {
    const {
      node: {
        frontmatter: {
          match,
        } = {},
      } = {},
    } = _fragment

    return {
      ..._fragments,
      ...match === 'listing-agnostack' && {
        defaultFragment: _fragment,
      },
      ...useMatch(match) && {
        fragment: _fragment,
      },
    }
  }, {})

  const headline = getTitle({ context: pageContext })
  const tagline = `${_tagline || TAGLINE}.`

  const children = (
    <>
      <div>
        {modifier && (
          <StyledCallout>
            <span>The industry&apos;s leading integration</span>
            {prependText ? (
              <>
                <span>&nbsp;between&nbsp;</span>
                <a href={prependLink} target="_blank" rel="noopener noreferrer">{prependText}</a>
                <span>&nbsp;and&nbsp;</span>
              </>
            ) : (
              <span>&nbsp;with&nbsp;</span>
            )}
            <a href={modifierLink} target="_blank" rel="noopener noreferrer">{modifierText}</a>
            <span>&nbsp;is powered by&nbsp;</span>
            <a href="/">agnoStack</a>
            <span>&nbsp;- learn more through the links below and/or&nbsp;</span>
            <ScheduleAnchor>schedule a demo</ScheduleAnchor>
            <span>!</span>
          </StyledCallout>
        )}
      </div>
      <ContentBody>
        <LinkGroups>
          {ensureArray(tagGroups).map(([template, links], groupIndex) => {
            const tag = stringNotEmpty(template) ? template : 'Pages'
            return (
              <LinkGroup key={`group-${groupIndex}`}>
                <div>
                  <TemplateTag template={template} hue={hues[groupIndex % hues.length]}>
                    {/* // TODO: explore unsnakecase? */}
                    {tag.replace(/_/, ' ')}
                  </TemplateTag>
                </div>
                <ul>
                  {ensureArray(links).map(({ link, path, title }, linkIndex) => (
                    <GroupItem key={`link-${groupIndex}-${linkIndex}`}>
                      {link ? (
                        <StyledAnchor href={link} target="_blank" rel="noopener noreferrer">
                          {title}
                        </StyledAnchor>
                      ) : (
                        <StyledLink to={ensureLeadingSlash(path)}>
                          {title}
                        </StyledLink>
                      )}
                    </GroupItem>
                  ))}
                </ul>
              </LinkGroup>
            )
          })}
        </LinkGroups>
        <FragmentBody>
          {(!fragment && !modifier) && (
            <Heading tag="2">
              {`${headline} ${tagline}`}
            </Heading>
          )}
          <PageFragment data={fragment || defaultFragment} fragments={fragments} />
          {!fragment && (
            <ListingFooter />
          )}
        </FragmentBody>
      </ContentBody>
    </>
  )

  if (stringEmpty(activeTag)) {
    return null
  }

  return (
    <div>
      <SEO
        title={`${headline} ${tagline}`}
        keywords={[activeTag, modifier && modifierText]}
      />
      <TemplatedPage
        headline={headline}
        tagline={tagline}
        format="medium"
        // data={{ children }}
        data={{ children, fragments }}
      />
    </div>
  )
})
